import * as Sentry from '@sentry/node';
import axios from 'axios';

export const lastSync = async (

) => {
  let userInfos;
  try {
    userInfos = await axios.post(
      process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat(`requests/lastSync/`),
      {},
    );
    return await userInfos.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    return error.response.status;
  }
};
