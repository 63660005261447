import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/el';

import React, { useEffect, useState, useCallback } from 'react';
import { isBrowser, isMobileOnly, isTablet } from 'react-device-detect';
import { lastSync } from '../../src/requests/client/lastSync';

import Copyright from '../assets/Copyright';
import SideBar from './SideBar';
import useResizeObserver from '../assets/useResizeObserver';
import { CardMedia } from '@material-ui/core';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    height: 90,
    ...theme.mixins.toolbar,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 0 20px 0',
    marginTop:'25px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
  },
  appBar: {
    position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    position: 'fixed',
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  dataCaption: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  inputRoot: {
    color: 'inherit',
  },
  icons: {
    flexGrow: 1,
  },
}));


export default function AppSideBar({ children }) {
  const classes = useStyles();
  const [AnchorE1, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(true);

  const [lastSyncDate, setLastSyncDate] = useState();
  const [ref, { contentRect }] = useResizeObserver();

  const getContentRect = useCallback(
    (key) => contentRect && Math.round(contentRect[key]),
    [contentRect]
  );

  useEffect(async () => {
    let date = await lastSync();
    setLastSyncDate(date.lastrun);
  }, []);


  useEffect(() => {
    isBrowser ? setOpen(true) : setOpen(false);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root} id='root-app-bar' ref={ref}>
      <CssBaseline />
      <AppBar
        id='app-bar'
        position='absolute'
        color='primary'
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <CardMedia
            component="img"
            src="/static/images/chambers-logo.jpg"
            alt="Evia Chamber Logo"
            style={{
              width: 170,
              height: 85,
              marginRight: "1rem"
            }}
          />
          <Typography
            component='h1'
            variant='h6'
            color='inherit'
            noWrap
            className={classes.title}
          >
            Στατιστική Ανάλυση Επιχειρηματικότητας
          </Typography>
          <div id='icons' className={classes.icons}></div>
            <>
              {!isMobileOnly && !isTablet ? (
                <>
                  <Typography
                    color='inherit'
                    noWrap
                    className={classes.dataCaption}
                  >
                    Δεδομένα μέχρι και
                    <i>
                      {' '.concat(moment(lastSyncDate).format('dddd, LL, LTS'))}
                    </i>
                  </Typography>
                </>
              ) : isTablet ? (
                <Typography
                  color='inherit'
                  noWrap
                  className={classes.dataCaption}
                >
                  Δεδομένα μέχρι και
                  <i>
                    {' '.concat(moment(lastSyncDate).format('DD/MM/YYYY, LTS'))}
                  </i>
                </Typography>
              ) : (
                <></>
              )}
            </>
        </Toolbar>
      </AppBar>
      {getContentRect('width') > 800 ? (
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose} aria-label='colapse-side-bar'>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
            <SideBar openSideBar={open} />
        </Drawer>
      ) : (
        <Drawer
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose} aria-label='colapse-side-bar'>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
            <SideBar openSideBar={open} />
        </Drawer>
      )}
      <main id='right-content' className={classes.content}>
        <div id='toolbar' className={classes.toolbar} />
        <div id='content'>{children}</div>
        {isMobileOnly ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography color='inherit' noWrap>
              Δεδομένα μέχρι και
              <i>
                {' '.concat(moment(lastSyncDate).format('DD/MM/YYYY, LTS'))}
              </i>
            </Typography>
          </div>
        ) : (
          <></>
        )}
        <Copyright />
      </main>
    </div>
  );
}
