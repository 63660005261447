import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import BarChartIcon from '@material-ui/icons/BarChart';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import RoomIcon from '@material-ui/icons/Room';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  nestedClose: {
    paddingLeft: 0,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  listItemSelected: {
    fontWeight: 450,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

const SideBar = (props) => {
  const [openRegistrations, setOpenRegistrations] = useState(true);
  const router = useRouter();
  const classes = useStyles();
  const { openSideBar } = props;

  const handleClickRegistrations = () => {
    setOpenRegistrations(!openRegistrations);
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        style={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: -17,
        }}
      >
        <Link href='/dashboard' legacyBehavior>
          <a style={{ color: 'inherit', textDecoration: 'none'}}>
            <ListItem button key='Αρχική' className={classes.listItem}>
              <ListItemIcon>
                <HomeIcon
                  fontSize='small'
                  style={
                    router.pathname.includes('dashboard') ||
                      router.pathname === '/'
                      ? { color: '#000000' }
                      : null
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary='Αρχική'
                classes={{
                  primary:
                    router.pathname.includes('dashboard') ||
                      router.pathname === '/'
                      ? classes.listItemSelected
                      : null,
                }}
              />
            </ListItem>
          </a>
        </Link>
        <Divider />
        <ListItem button onClick={handleClickRegistrations}>
          <ListItemIcon>
            <CompareArrowsIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Εγγραφές/Διαγραφές' />
          {openRegistrations ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        <Collapse in={openRegistrations} timeout='auto' unmountOnExit>
            <>
              <List>
                <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItem
                    button
                    key='Στο χρόνο'
                    className={
                      openSideBar ? classes.nested : classes.nestedClosed
                    }
                    onClick={() => router.push('/establishments')}
                  >
                    <ListItemIcon>
                      <CompareArrowsIcon
                        style={
                          router.pathname.includes('establishments')
                            ? { color: '#000000' }
                            : null
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary='Στο χρόνο'
                      classes={{
                        primary: router.pathname.includes('establishments')
                          ? classes.listItemSelected
                          : null,
                      }}
                    />
                  </ListItem>
                </ListItem>
                <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItem
                    button
                    key='Ανά Νομική Μορφή'
                    className={
                      openSideBar ? classes.nested : classes.nestedClosed
                    }
                    onClick={() => router.push('/regdelgrouped')}
                  >
                    <ListItemIcon>
                      <BarChartIcon
                        style={
                          router.pathname.includes('regdelgrouped')
                            ? { color: '#000000' }
                            : null
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary='Ανά Νομική Μορφή'
                      classes={{
                        primary: router.pathname.includes('regdelgrouped')
                          ? classes.listItemSelected
                          : null,
                      }}
                    />
                  </ListItem>
                </ListItem>
                <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItem
                    button
                    key='Ανά Δραστηριότητα'
                    className={
                      openSideBar ? classes.nested : classes.nestedClosed
                    }
                    onClick={() => router.push('/kadgrouped')}
                  >
                    <ListItemIcon>
                      <TrendingUpIcon
                        style={
                          router.pathname.includes('kadgrouped')
                            ? { color: '#000000' }
                            : null
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary='Ανά Δραστηριότητα'
                      classes={{
                        primary: router.pathname.includes('kadgrouped')
                          ? classes.listItemSelected
                          : null
                      }}
                    />
                  </ListItem>
                </ListItem>
              </List>
            </>
        </Collapse>
          <>
          <Divider />
            <Link href='/demography'>
              <a style={{ color: 'inherit', textDecoration: 'none' }}>
                <ListItem
                  button
                  key='Δημογραφία'
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <ScatterPlotIcon
                      style={
                        router.pathname.includes('demography')
                          ? { color: '#000000' }
                          : null
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary='Δημογραφία'
                    classes={{
                      primary: router.pathname.includes('demography')
                        ? classes.listItemSelected
                        : null,
                    }}
                  />
                </ListItem>
              </a>
            </Link>
            <Divider />
          </>
          <>
            <Link href='/geography'>
              <a style={{ color: 'inherit', textDecoration: 'none' }}>
                <ListItem
                  button
                  key='Γεωγραφική απεικόνιση'
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <RoomIcon
                      style={
                        router.pathname.includes('geography')
                          ? { color: '#000000' }
                          : null
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary='Γεωγραφική απεικόνιση'
                    classes={{
                      primary: router.pathname.includes('geography')
                        ? classes.listItemSelected
                        : null,
                    }}
                  />
                </ListItem>
              </a>
            </Link>
            <Divider />
          </>
      </div>
    </div>
  );
};
export default SideBar;
