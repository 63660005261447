import { Container, Divider, Typography } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    left: 0,
    bottom: 0,
    width: "100%",
    overflow: "hidden",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    alignItems: "center",
  },
  fixedHeight: {
    height: "100%",
  },
}));

export default function Copyright() {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Divider/>
      <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '0.5rem' }}>
        {" © ".concat(process.env.copyrightDataChamber)}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '0.5rem' }}>
        {" © ".concat(process.env.copyrightDataMunicipality)}
      </Typography>
      <Container style={{width: "100%", display: "flex", justifyContent: "center", marginTop: '1rem'}}> 
      {process.env.footerEspa ? 
      <img src= "/static/images/espa.jpg" alt='espa2014-2020 EU' 
        style={{maxWidth:"100%", maxHeight:"100%"}} 
        />  
      : <></>
      }    
      </Container>
      <InputLabel align="center" className={classes.footerText} style={{ margin: '1rem 0' }}>
                        <a href="/TermsConditions" >
                              Όροι Χρήσης
                        </a>
                        {' '}|{' '}
                        <a href="/PrivacyPolicy" >
                            Πολιτική Ασφάλειας
                        </a>
      </InputLabel>

    </Container>
  );
}
