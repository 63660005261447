import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/node';
import { useMemo, useState } from 'react';
import Snackbar from '../components/assets/Snackbar';
import Layout from '../layout/Layout';
import { ErrorHandling } from '../src/context/ErrorContext';
import theme from '../src/theme/theme';
import '../styles/globals.css';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

function MyApp({ Component, pageProps, err }) {
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    setOpenModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenModal(false);
  };


  return (
          <ThemeProvider theme={theme}>
            <ErrorHandling.Provider
              value={{ openModal, setOpenModal, handleClose, handleClick }}
            >
              <Layout>
                <Snackbar />
                <Component {...pageProps} err={err} />
              </Layout>
            </ErrorHandling.Provider>
          </ThemeProvider>
  );
}

export default MyApp;
